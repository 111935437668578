<template>
  <div class="eye-share--view" v-show="timeSeconds > -1">
    <div class="eye-share--work--container" v-show="timeSeconds > 0">
      <div class="icon--container">
        <img class="eye__icon" src="../../assets/icons/eye-icon.svg">
      </div>
      <div class="video--container">
        <div class="videoplayer">
          <video-js :width="width" :height="height" id=vid1 class="vjs-default-skin" controls playsinline>
          </video-js>
        </div>
      </div>
      <div class="timer--container">
        <p class="title__text">ВРЕМЯ ДОСТУПА</p>
        <p class="time__text">{{timeText}}</p>
      </div>
    </div>
    <div class="eye-share--expired--container" v-show="timeSeconds === 0">
      <div class="timer--container">
        <p class="title__text">ВРЕМЯ ИСТЕКЛО</p>
        <p class="time__text">{{timeText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import("video.js/dist/video-js.min.css")
import videojs from "video.js"
import("@videojs/http-streaming")


export default {
  name: 'EyeShareView',
  data () {
    return {
      timeSeconds: -1,
      src : '',
      isShowVideo: false
    }
  },
  computed: {
    isMobile(){
      return screen.width < 992
    },
    width(){
      return (this.isMobile) ? 375 : 670
    },
    height(){
      return (this.isMobile) ? 200 : 400
    },
    timeText () {
      if (this.timeSeconds < 0) return '00:00'
      // Hours, minutes and seconds
      var hrs = ~~(this.timeSeconds / 3600);
      var mins = ~~((this.timeSeconds % 3600) / 60)
      var secs = ~~this.timeSeconds % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''


      ret += '' + (hrs < 10 ? '0' : '')
      ret += '' + hrs + ':'
      ret += '' + (mins < 10 ? '0' : '')
      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    shareData () {
      return this.$store.getters.SHARE_DATA
    },
  },
  async mounted () {
    const token = this.$route.params.token
    await this.$store.dispatch('GET_SHARE_DATA_BY_TOKEN', { token: token })
    const secondsTo = (moment(this.shareData.expiredAt.date).toDate().getTime() - new Date().getTime()) / 1000
    this.timeSeconds = (secondsTo < 0) ? 0 : secondsTo
    const interval = setInterval(() => {
      if (this.timeSeconds <= 0) {
        clearInterval(interval)
      } else {
        this.timeSeconds = (moment(this.shareData.expiredAt.date).toDate().getTime() - new Date().getTime()) / 1000
      }
    }, 1000)

    const player = videojs('vid1',{ playbackRates: [0.5, 1, 1.5, 2]})
    player.src(this.shareData.uri)
    Chatra('kill')
  }
}
</script>

<style scoped lang="scss">
  .eye-share--view{
    width: 100%;
    height: 100%;
    background-color: #0D0D0D;
    overflow: hidden;

    .icon--container{
      margin-top: 50px;

      @include for-mobile-devices{
        margin-top: 30px;
      }
      img{
        width: 64px;
        height: 24px;

        @include for-mobile-devices{
          width: 50px;
          height: 21px;
        }
      }
    }

    .video--container{
      margin-top: 81px;
      width: 670px;
      height: 400px;

      @include for-mobile-devices{
        width: 375px;
        height: 200px;
      }

      .videoplayer{
        width: 100%;
        height: 100%;
      }

    }

    .timer--container{
      margin-top: 53px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include for-mobile-devices{
        margin-top: 0;
        position: absolute;
        bottom: 30px;
      }

      p{
        text-align: center;
        font-family: Roboto, "sans-serif";
        color: white;
        font-size: 13px;
        line-height: 18px;

      }

      .title__text{
        letter-spacing: 0.6px;
      }

      .time__text{
        letter-spacing: 10px;
        width: 120px;
        margin-top: 3px;
      }
    }
  }

  .eye-share--expired--container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .timer--container{
      @include for-mobile-devices{
        position: relative;
      }
    }
  }

  .eye-share--work--container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  video{
    outline: none !important;
  }




</style>
